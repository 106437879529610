import React, { useState, useContext, useEffect } from "react";
import { AppProvider } from "../../App";
import { BiCart } from "react-icons/bi";
import { RiEyeLine } from "react-icons/ri";
import { toast } from "react-toastify";
import PriceSelector from "./PriceSelector";

export const TrendingCard = ({ item }) => {
  const { addItem, setCartChanged, cartChanged } = useContext(AppProvider);
  const [showPriceSelector, setShowPriceSelector] = useState(false);
  const [showGuestPrompt, setShowGuestPrompt] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);

  // Check and set guest ID in localStorage if it doesn't exist
  const getGuestId = () => {
    let guestId = localStorage.getItem("guestId");
    if (!guestId) {
      // If no guest ID exists, create a new one
      guestId = `guest-${Math.floor(Math.random() * 1000000)}`;
      localStorage.setItem("guestId", guestId);
    }
    return guestId;
  };

  useEffect(() => {
    getGuestId(); // Ensure the guest ID is set when the component mounts
  }, []);

  const closedDates = ["2024-11-28", "2024-11-29", "2024-11-30"];

  const isWithinOrderTime = () => {
    const currentTime = new Date();
    const currentDate = currentTime.toISOString().split("T")[0];

    if (closedDates.includes(currentDate)) return false;

    const startTime = new Date();
    startTime.setHours(11, 0, 0);

    const endTime = new Date();
    endTime.setHours(19, 30, 0);

    return currentTime >= startTime && currentTime <= endTime;
  };

  const handleAddToCart = (selectedItems) => {
    // if (!localStorage.getItem("logged")) {
    //   setShowGuestPrompt(true); // Show the guest prompt
    //   // completeAddToCart(selectedItems);
    // } else {
      completeAddToCart(selectedItems);
    // }
  };

  const completeAddToCart = (selectedItems) => {

    // if(isWithinOrderTime){

    //   toast.warn('Open 11AM - 7:30PM. Closed on Sundays')
    //   return;
    // }

    const guestId = getGuestId(); // Retrieve the guest ID

    const selectedItemData = {
      id: item.id,
      name: item.name,
      price: selectedItems.price,
      size: selectedItems.size,
      base: selectedItems.base,
      flavors: selectedItems.flavors,
      toppings: selectedItems.toppings || [],
      toppingsExtra: selectedItems.toppingsExtra || [],
      meat: selectedItems.meat || "",
      spiceLevel: selectedItems.spiceLevel,
      carb: selectedItems.carb,
      drinks: selectedItems.drinks,
      guest: guestId, // Include guestId in the order
    };

    addItem(
      selectedItemData,
      localStorage.getItem("logged") || localStorage.getItem("guestId")
    );
    toast.success(`${item.name} is added to the cart`);
    setCartChanged(!cartChanged);
  };

  const handleLoginRedirect = () => {
    setShowGuestPrompt(false); // Close the prompt
    setTimeout(() => {
      window.location.href = "/admin"; // Redirect to the login page
    }, 2000);
  };

  return (
    <div className="gap-4">
      <div key={item.id} className="relative w-full group rounded-lg p-4">
        <img
          className="w-full h-80 lg:h-auto group-hover:scale-105 transition-all duration-300 ease-linear object-cover rounded-md mb-4"
          src={item.image}
          alt={item.name}
        />
        <div className="absolute bottom-[40px] md:bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:hidden group-hover:block transition-all duration-500 ease-linear">
          <div className="flex flex-row items-center space-x-3">
            <a
              href={`/ingredientPage/${item.id}`}
              className="p-2 text-white rounded-full bg-orange-500 hover:bg-orange-300 cursor-pointer"
            >
              <RiEyeLine className="text-3xl md:text-2xl" />
            </a>
            <button
              onClick={() => {
                if (
                  item.prices?.length > 1 ||
                  item.meats ||
                  item.carb ||
                  item.category === "Slushies" ||
                  item.category === "Smoothies" ||
                  item.category === "Fruit Teas" ||
                  item.category === "Soft Drinks"
                ) {
                  setShowPriceSelector(true);
                } else {
                  handleAddToCart({
                    price: item.price,
                    size: null,
                    toppings: [],
                  });
                }
              }}
              className="rounded-full p-2 text-white bg-orange-500 hover:bg-orange-300 cursor-pointer"
            >
              <BiCart className="text-3xl md:text-2xl" />
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center md:pt-0">
          <p className="font-semibold text-xl">{item.name}</p>
          <p className="font-bold p-4 text-2xl text-green-500 rounded-full flex items-center justify-center">
            ${item.prices?.length > 1 ? "More than one price" : item.price}
          </p>
        </div>
      </div>
      {showPriceSelector && (
        <PriceSelector
          base={item.base}
          toppings={item.toppings}
          flavors={item.flavors}
          toppingsExtra={item.toppingsExtra}
          prices={item.prices}
          meats={item.meats}
          sizes={item.sizes}
          drinks={item.drinks}
          price={item.price}
          spiceLevels={item.spices}
          carb={item.carb}
          onSelect={(priceDetails) => {
            const {
              totalPrice,
              size,
              selectedToppings,
              selectedToppingsExtra,
              selectedMeats,
              selectedSpiceLevel,
              selectedCarb,
              selectedBase,
              selectedFlavor,
              selectedDrinks,
            } = priceDetails;
            setSelectedPrice(totalPrice);
            setShowPriceSelector(false);
            handleAddToCart({
              price: totalPrice || item.price,
              size,
              toppings: selectedToppings,
              toppingsExtra: selectedToppingsExtra,
              meat: selectedMeats,
              spiceLevel: selectedSpiceLevel,
              carb: selectedCarb,
              base: selectedBase,
              flavors: selectedFlavor,
              drinks: selectedDrinks,
            });
          }}
          onClose={() => setShowPriceSelector(false)}
        />
      )}

      {/* Guest Prompt Modal */}
      {showGuestPrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9000]">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-96 shadow-lg">
            <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-gray-100">
              Continue as Guest or Login
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              You need to be logged in to save your order history. You can
              proceed as a guest, but your order details will not be saved.
            </p>
            <div className="flex justify-between">
              <button
                onClick={() => {
                  setShowGuestPrompt(false);
                  handleAddToCart({
                    price: item.price,
                    size: null,
                    toppings: [],
                  });
                }}
                className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-400"
              >
                Continue as Guest
              </button>
              <button
                onClick={() => {
                  setShowGuestPrompt(false);
                  toast.info("Redirecting to login...");
                  handleLoginRedirect();
                }}
                className="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-100 px-4 py-2 rounded hover:bg-gray-300 dark:hover:bg-gray-600"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
